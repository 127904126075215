import React from "react";
import { Container, Row, Col } from "react-grid-system";
import Headline from "../../components/common/Headline";

function KieliProPrivacy() {
    return (
        <section className="section section-contact section-contact-1">
            <div className="display-spacing">
                <Container>
                <Headline label="KieliPro" title="KieliPro: Finnish Dictionary" divider_1={true} position="center" />
                    <Row>
                        <Col lg={7}>
                            <div className="contact-content">
                                <header className="el-heading">
                                    <h2 className="el-icon">
                                        <span className="el-icon-title">PRIVACY POLICY</span>
                                    </h2>
                                    <p><strong>Last Updated: 5 November 2024</strong></p>
                                    <p>At CoderLife Oy, we take your privacy seriously. This Privacy Policy describes how we collect, use, and share your information when you use our app, KieliPro.</p>

                                    <h4>
                                        <span>1. Information We Collect</span>
                                    </h4>
                                    <div className="divider divider-1"></div>
                                    <p>KieliPro does not collect any personal data from its users. The app is designed to function without requiring access to personal information, ensuring your privacy is protected.</p>

                                    <h4>
                                        <span>2. Data Types Collected</span>
                                    </h4>
                                    <div className="divider divider-1"></div>
                                    <p>To enhance app performance and provide a better user experience, KieliPro collects only non-personalized usage data. This data is anonymous and helps us understand how users interact with different app features.</p>
                                    <ul>
                                        <li><strong>User Content:</strong> The app does not collect, store, or share any user-generated content.</li>
                                        <li><strong>Usage Data:</strong> We track feature usage, general app performance metrics, and event data (such as actions taken within the app). This anonymous, non-personalized data does not include any identifiable information. We analyze aggregated data to improve KieliPro’s functionality and user experience.</li>
                                        <li><strong>Location Data:</strong> The app does not access or use your device’s location.</li>
                                        <li><strong>Contact Information:</strong> We do not collect your contact information, such as email address or phone number.</li>
                                        <li><strong>Identifiers:</strong> The app does not collect device identifiers or any other unique identifiers.</li>
                                        <li><strong>Financial Data:</strong> Financial data related to in-app purchases is handled securely by the App Store and is not collected or stored by KieliPro. We only receive information about successful transactions (e.g., subscription status) to provide access to premium features.</li>
                                        <li><strong>Health Data:</strong> The app does not access or collect any health-related data.</li>
                                    </ul>

                                    <h4>3. In-App Purchases</h4>
                                    <div className="divider divider-1"></div>
                                    <p>KieliPro offers in-app purchases, including a premium subscription that removes ads and provides access to additional features. All purchases are processed securely through the App Store. We do not collect or store any financial information; we only receive notification of successful transactions to grant access to premium content.</p>

                                    <h4>4. How We Use Information</h4>
                                    <div className="divider divider-1"></div>
                                    <p>KieliPro uses anonymous usage data to improve app performance, optimize user experience, and develop new features. No personal data is collected or processed by the app, and all data is analyzed in an aggregated and non-personalized manner.</p>

                                    <h4>5. Third-Party Sharing</h4>
                                    <div className="divider divider-1"></div>
                                    <p>KieliPro does not share any information with third parties. Since no personal data is collected, there is no data to share.</p>

                                    <h4>6. Data Security</h4>
                                    <div className="divider divider-1"></div>
                                    <p>Although KieliPro does not collect or store personal data, we prioritize security in all aspects of our app development. We follow industry best practices to ensure that your experience with our app is safe and secure.</p>

                                    <h4>7. Terms of Use</h4>
                                    <div className="divider divider-1"></div>
                                    <p>KieliPro adheres to Apple’s standard <a href="https://www.apple.com/legal/internet-services/itunes/dev/stdeula/">Terms of Use (EULA)</a>. By using KieliPro, you agree to these terms. Please review Apple's EULA for more information.</p>

                                    <h4>8. Changes to This Privacy Policy</h4>
                                    <div className="divider divider-1"></div>
                                    <p>We may update this Privacy Policy from time to time. Any changes will be reflected on this page, and the date at the top of the policy will be updated. We encourage you to review this Privacy Policy periodically.</p>

                                    <h4>9. Contact Us</h4>
                                    <div className="divider divider-1"></div>
                                    <p>If you have any questions or concerns about this Privacy Policy or our app, please contact us at:</p>

                                    <p>
                                        <strong>CoderLife Oy</strong><br />
                                        Email: <a href="mailto:ngoc@coder.life">ngoc@coder.life</a><br />
                                    </p>
                                </header>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </section>
    );
}

export default KieliProPrivacy;
import React from "react";
import { Container, Row, Col } from "react-grid-system";
import Headline from "../../components/common/Headline";
import { Link } from "react-router-dom";

function AppMarketing() {
    return (
        <section className="section section-marketing section-marketing-1">
            <div className="display-spacing">
                <Container>
                    <Headline label="KieliPro" title="KieliPro: Finnish Dictionary" image="/assets/images/kielipro.png" divider_1={true} position="center" />
                    <Row style={{marginBottom: 30}}>
                        <Col lg={7}>
                            <img src="/assets/images/kielipro/KieliPro_FeatureImage.png" alt="KieliPro" />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={7}>
                            <div className="marketing-content">
                                <header className="el-heading">
                                    <h2 className="el-icon">
                                        <span className="el-icon-title">About KieliPro</span>
                                    </h2>
                                    <p>KieliPro is the ultimate Finnish dictionary app designed to help you learn and master the Finnish language. With a range of features and an intuitive interface, KieliPro makes it easy for users of all levels to improve their vocabulary and understanding of Finnish.</p>
                                    
                                    <h4>
                                        <span>1. Key Features</span>
                                    </h4>
                                    <div className="divider divider-1"></div>
                                    <ul>
                                        <li><strong>Comprehensive Dictionary:</strong> Quickly search Finnish words and get precise English translations.</li>
                                        <li><strong>Improved Word Forms Table:</strong> A detailed and precise declension table gives you a comprehensive view of different word forms, helping you grasp Finnish grammar more easily.</li>
                                        <li><strong>Original Word Forms:</strong> Link easily to the original form of a word. For example, when viewing the partitive singular form "päiväkotia," you can access the base word "päiväkoti" with just a tap.</li>
                                        <li><strong>Favorites Feature:</strong> Save your favorite words for easy access! Build your personal vocabulary list and review your most important words anytime.</li>
                                        <li><strong>History Widget:</strong> Stay on top of your recent searches! The new History Widget displays your recently searched words on your home screen, helping you memorize them and build your language skills seamlessly.</li>
                                        <li><strong>Search History:</strong> The app now automatically saves your recent searches, so you can easily revisit and review words you've looked up.</li>
                                        <li><strong>Vocabulary Sets:</strong> Explore themed vocabulary sets with flashcards! Perfect for learning words in context and expanding your vocabulary on different topics.</li>
                                        <li><strong>User-Friendly Interface:</strong> Designed for simplicity, KieliPro makes it easy for everyone to use, whether you're a beginner or fluent in Finnish.</li>
                                    </ul>

                                    <h4>
                                        <span>2. Why Use KieliPro?</span>
                                    </h4>
                                    <div className="divider divider-1"></div>
                                    <p>KieliPro is designed for learners of all levels. Whether you’re a beginner or an advanced learner, the app provides the tools you need to master Finnish vocabulary and pronunciation.</p>

                                    <h4>3. Why We Show Ads</h4>
                                    <div className="divider divider-1"></div>
                                    <p>We are committed to keeping KieliPro free for all users, and to achieve this, we have integrated ads into the app. The revenue generated from these ads helps us cover server costs and ongoing development efforts. By showing ads, we can ensure that you continue to enjoy KieliPro without any upfront cost, while we maintain and improve the app with new features and better performance.</p>

                                    <h4>
                                        <span>4. Privacy Policy</span>
                                    </h4>
                                    <div className="divider divider-1"></div>
                                    <p>Read our <Link to="//kielipro-privacy" activeClassName="active">Privacy Policy</Link> to understand how we handle your data.</p>
                                    
                                    <h4>5. How to Download</h4>
                                    <div className="divider divider-1"></div>
                                    <p>KieliPro is available for download on the App Store and Play Store. Get started today and enhance your Finnish learning experience.</p>
                                    <p>
                                        <a href="https://apps.apple.com/fi/app/kielipro-finnish-dictionary/id6623018184" target="_blank" rel="noopener noreferrer">
                                        <img src="/assets/images/kielipro/DownloadOnAppStore_Badge.png" alt="Download on App Store" />
                                        </a>
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <a href="https://play.google.com/store/apps/details?id=life.coder.finlife&pcampaignid=web_share" target="_blank" rel="noopener noreferrer">
                                            <img src="/assets/images/kielipro/GetItOnGooglePlay_Badge.png" alt="Download on App Store"  />
                                        </a>
                                    </p>

                                    <h4>6. Contact Us</h4>
                                    <div className="divider divider-1"></div>
                                    <p>If you have any questions or feedback, we'd love to hear from you. Feel free to reach out to us for any queries.</p>
                                    <p>
                                        <strong>CoderLife Oy</strong><br />
                                        Email: <a href="mailto:ngoc@coder.life">ngoc@coder.life</a><br />
                                    </p>
                                </header>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </section>
    );
}

export default AppMarketing;
